$(document).on("page:show", function() {
  $(".ajax-loader").hide();
  $.ajaxSetup({
    cache: false,
    beforeSend: function() {
      $(".ajax-loader").show();
    },
    complete: function() {
      $(".ajax-loader").hide();
    },
    success: function() {
      $(".ajax-loader").hide();
    },
    error: function() {
      $(".ajax-loader").hide();
    }
  });

  $(document).on("ajax:before", "form[data-remote]", function() {
    $(".ajax-loader").show();
    $(this).find("input[type=submit]").prop("disabled", true);
    $("[data-form='" + $(this)[0].id + "']").attr("disabled", "disabled");
  }).bind("ajax:complete", function() {
    $(".ajax-loader").hide();
    $(this).find("input[type=submit]").prop("disabled", false);
    $(".btn").removeAttr("disabled");
  }).bind("ajax:success", function() {
  }).bind("ajax:error", function() {
  });

  $(document).on("submit", "form", function() {
    $(this).find("input[type=submit]").prop("disabled", false);
  });

  $("js-form-submit").click(function() { $(this).closest("form").submit(); });

  $(document).on("click", ".js-open-modal", function() {
    $("#" + $(this).data("modal")).modal("show");
  });
});
